import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './config';
import { ActivatedRoute } from '@angular/router';
import {BehaviorSubject, ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  private configData: Config;

  private configSubject : ReplaySubject<Config> = new ReplaySubject();

  private ipRecognitionApi: string;

  public static readonly  availableRegions = ['US','GB'];//['GB', 'ES', 'DE'];

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
  ) {
    this.configData = {};
    this.ipRecognitionApi = 'https://api.ipregistry.co/?key=4lftc6k7q1gtd02p';
  }

  load(configUrl: string): void {

    // replace logic to:
      // normal config first, country code, if it exists, merge

    this.activatedRoute.queryParams.subscribe((params) => {
          let loadConfig:any;

      if (!localStorage.getItem('country')||params.lang)
      {
        this.http.get(this.ipRecognitionApi).subscribe((res: any) => {
          if( res ) {
            const countryCode = res.location.country.code.toLowerCase();
            localStorage.setItem('country', countryCode)

            if (
              ConfigService.availableRegions.indexOf(res.location.country.code) !== -1
            && !params.lang
            ) {
              console.log('looking for country code', res.location.country.code);

              loadConfig = configUrl.replace(
                '.json',
                `-${res.location.country.code.toLowerCase()}.json`
              );
            }
           else {
            loadConfig = params.lang
              ? configUrl.replace('.json', `-${params.lang}.json`)
              : configUrl;
            if (params.lang)
              localStorage.setItem('country', params.lang);
          }
        }
         else {
           loadConfig = configUrl;
          }
         this.loadConfig(loadConfig);
        });
    }
      else { // localstorage has it
        const countryCode = localStorage.getItem('country');
        loadConfig = configUrl.replace(
          '.json',
          `-${countryCode}.json`
        );
        this.loadConfig(configUrl);
      }
      });
  }

   private loadConfig(loadConfig: string): void {
    this.http
      .get<Config>(loadConfig)
      .toPromise()
      .then((config: Config) => {
        this.configData = config;
        this.configSubject.next(config);
      })
      .catch((e) => {
      })
    ;
  }

  get config() {
    return this.configData;
  }

  waitForConfig() {
    return this.configSubject.asObservable();
  }


}
