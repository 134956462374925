<!--<div
  (click)="startVanta()"
  style="height:100px; width:100%; border:solid 1px green" id="animate"></div>-->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar></mat-toolbar>

    <div class="regions">

      <div class="region" *ngFor="let country of  availableRegions" [class.selected]="country.toLowerCase()===versionCountry">
          <a  href="/gallery?lang={{country.toLowerCase()}}">
        <img src="assets/images/{{country.toLowerCase()}}.svg"/>

             </a>

      </div>
    </div>

    <mat-nav-list *ngIf="labels">
      <a mat-list-item href="/gallery">{{labels.menu.gallery}}</a>
<!--      <a mat-list-item href="/list">{{labels.menu.list}}</a>-->
    <div>  <a mat-list-item href="/spots">{{labels.menu.spots}}
      </a>
      <a href="/spots">
      <img [src]="labels.menu.spotsImg" style="margin-left:1rem;width:5rem; height: 5rem"/>
    </a>
    </div>
     <!-- <a mat-list-item href="/tips">{{labels.menu.tips}}</a>-->
      <a mat-list-item href="mailto:{{labels.menu.contactEmail}}">{{labels.menu.contact}}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" >
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <ng-container *ngIf="labels">
        <div
          class="title"
          style="background-image:url('{{ labels.imgL }}')">
        </div>

        <div
          class="llamas"
          style="background-image:url('{{ labels.imgR }}')"
        ></div>
      </ng-container>
    </mat-toolbar>

    <div class="content">
      <router-outlet> </router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
