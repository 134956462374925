import { Injectable } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AlpacaService {

  constructor(private activatedRoute: ActivatedRoute) { }

  public detectAlpaca(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
        if(params.alp) {
          localStorage.setItem('alpaca', params.alp);
        }
    });
  }
}
