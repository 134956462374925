import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './services/config.service';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MainComponent } from './main/main.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AlpacaService } from './alpaca/alpaca.service';
import {AlpacaModule} from "./alpaca/alpaca.module";
import { GenesisComponent } from './js/components/genesis/genesis.component';

export function initConfig(
  configService: ConfigService,
  alpacaService: AlpacaService
) {
  return () => {
    alpacaService.detectAlpaca();
    return configService.load(environment.configUrl);
  };
}

@NgModule({
  declarations: [AppComponent, MainComponent, GenesisComponent],
  imports: [
    MatListModule,
    MatSidenavModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    LayoutModule,
    MatButtonModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigService, AlpacaService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
// todo: text
// todo:squares resp
// todo menu and title l
