import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { Config } from '../services/config';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  labels: any;
  versionCountry = 'US';
  availableRegions : String[] = []

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    // set through RX JS
    // setTimeout(() => {
    //   const config: Config = this.configService.config;
    //   this.labels = config.ui?.labels?.main;
    // }, 500);

    this.configService
      .waitForConfig()
      .subscribe((config) => {
        this.labels = config.ui?.labels.main;
        let userCountry = localStorage.getItem('country') || '';

        this.availableRegions = ConfigService.availableRegions;

        if (ConfigService.availableRegions.map(r=>r.toLowerCase()).indexOf(userCountry) !== -1)
        {
          this.versionCountry = userCountry;
        }

        setTimeout(() =>{
          this.versionCountry = localStorage.getItem('country') || '';
        },100)
      });



  }


}
