import { Component, OnInit } from '@angular/core';
import { ConfigService } from './services/config.service';
import { Config } from './services/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  labels: any;
  constructor(private configService: ConfigService) {
    this.labels = {};
  }
  ngOnInit() {
/*    setTimeout(() => {
      const config: Config = this.configService.config;
      this.labels = config.ui?.labels.main;
    }, 500);*/

    this.configService
      .waitForConfig()
      .subscribe((config) => this.labels = config.ui?.labels.main);
  }
}
